import "./App.css";
import MainRoute from "./route/MainRoute";
import "./Global";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return <MainRoute />;
}

export default App;
