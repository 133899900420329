import React, { memo } from "react";
import Modal from "@mui/joy/Modal";
import Sheet from "@mui/joy/Sheet";
import { motion } from "framer-motion";
import { ModalClose } from "@mui/joy";
import Logo from "../assets/Ratria_Logo.png";

function BlogPop({ open, setOpen, content, title }) {
  var doc = new DOMParser().parseFromString(content, "text/html");
  let CONTENT = doc.body.textContent || "";
  return (
    <React.Fragment>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{
            minWidth: `calc(100% - 20%)`,
            minHeight: `calc(100% - 20%)`,
            maxHeight: `calc(100% - 20%)`,
            maxWidth: `calc(100% - 20%)`,
            borderRadius: "20px",
            p: 3,
            border: "2px solid #fff",
            boxShadow: "md",
          }}
          style={{ backgroundColor: "#1A3A63" }}
        >
          <ModalClose variant="plain" sx={{ m: 1, color: "#fff" }} />
          <div
            style={{
              width: "100%",
              height: "75vh",
              overflowY: "scroll",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={Logo}
                style={{
                  width: "80px",
                }}
              />
            </div>
            <motion.main
              className="main__container"
              initial={{ width: 0 }}
              animate={{ width: "100%" }}
              exit={{ x: "100%", opacity: 0 }}
              transition={{ duration: 2 }}
            >
              <div
                style={{
                  width: "95%",
                }}
              >
                <h3 style={styles.htxt}>
                  {title} <span style={styles.spntxt}>(2 Minutes Read)</span>
                </h3>
                <p style={styles.txt}>{CONTENT}</p>
                {/* <div
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                ></div>
                <p></p> */}
              </div>
            </motion.main>
          </div>
        </Sheet>
      </Modal>
    </React.Fragment>
  );
}

const styles = {
  txt: {
    fontSize: "18px",
    color: "#fff",
    fontWeight: 500,
    lineHeight: 1.5,
  },
  htxt: {
    fontSize: "24px",
    color: "#fff",
    fontWeight: 600,
  },
  spntxt: {
    fontSize: "14px",
    color: "#fff",
    fontWeight: 400,
  },
};

export default memo(BlogPop);
