import React from 'react'

function Content({ children }) {
    return (
        <>
            {children}
        </>
    )
}

export default Content