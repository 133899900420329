import React from "react";
import { Route, Routes } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import Dashboard from "../pages/Dashboard";
import NextPage from "../pages/NextPage";
import ListAllData from "../pages/ListAllData";
import AudioPage from "../pages/AudioPage";
import VideoPage from "../pages/VideoPage";
import ContentPage from "../pages/ContentPage";

function AppRoute() {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route index element={<Dashboard />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/Result" element={<NextPage />} />
        <Route path="/moreResult" element={<ListAllData />} />
        <Route path="/audio" element={<AudioPage />} />
        <Route path="/video" element={<VideoPage />} />
        <Route path="/content" element={<ContentPage />} />
      </Route>
    </Routes>
  );
}

export default AppRoute;
