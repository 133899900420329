import * as React from "react";
import Modal from "@mui/joy/Modal";
import Sheet from "@mui/joy/Sheet";
import ReactPlayer from "react-player";
import { Grow } from "@mui/material";
import { ModalClose } from "@mui/joy";

const VideoPop = ({ open, setOpen, url }) => {
  return (
    <React.Fragment>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{
            minWidth: `calc(100% - 20%)`,
            minHeight: `calc(100% - 20%)`,
            maxHeight: `calc(100% - 20%)`,
            maxWidth: `calc(100% - 20%)`,
            borderRadius: "20px",
            p: 1,
            border: "2px solid #fff",
            boxShadow: "md",
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#1A3A63",
            height: "100%",
            borderRadius: "10px",
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1, color: "#fff" }} />
          <ReactPlayer
            style={styles.player}
            playing
            url={url}
            width="100%"
            height="100%"
            controls
          />
        </Sheet>
      </Modal>
    </React.Fragment>
  );
};

export default React.memo(VideoPop);
const styles = {
  player: {
    // position: "absolute",
    // top: 0,
    // left: 0,
    borderRadius: "20px",
  },
};
