import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AppRoute from "./AppRoute";
import { AnimatePresence } from "framer-motion";
import AddForm from "../pages/AddForm";

function MainRoute() {
  return (
    <AnimatePresence>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Navigate to="/app" />} />
          <Route path="/app/*" element={<AppRoute />} />
          <Route path="/addform/*" element={<AddForm />} />
        </Routes>
      </BrowserRouter>
    </AnimatePresence>
  );
}

export default MainRoute;
