import { Grid, Grow, Typography } from "@mui/material";
import React, { memo, useState } from "react";
import AudioBg from "../assets/audiobg2.png";
import AudioPop from "./AudioPop";
import { Link, useLocation, useNavigate } from "react-router-dom";

function AudioPage() {
  const location = useLocation();
  const audioData = location.state.finalResult;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const divStyle = { width: `calc(100% - 15%)`, height: `calc(100% - 5%)` };
  const [checked, setChecked] = useState(true);

  const openVideo = () => {
    setOpen(true);
  };
  return (
    <Grid
      container
      style={divStyle}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid item sm={7} xs={12} style={styles.container}>
        <Grid
          item
          container
          direction={"column"}
          xs={12}
          style={{
            height: "70%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "45%",
            }}
          >
            {audioData &&
              (audioData.Acontent ||
                audioData.Alink ||
                audioData.Athumbnail ||
                audioData.Atittle) && (
                <Grow
                  in={checked}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(checked ? { timeout: 2000 } : {})}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      height: "80%",
                      textAlign: "center",
                      backgroundImage: `url(${AudioBg})`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      width: "100%",
                      cursor: "pointer",
                      alignSelf: "center",
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "center",
                    }}
                    onClick={() => openVideo("Audio")}
                  >
                    <Grid
                      sx={{
                        width: "90%",
                        marginBottom: "5%",
                        paddingX: "10%",
                        height: "25%",
                        overflow: "scroll",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { lg: "20px", md: "16px" },
                          fontWeight: 600,
                        }}
                      >
                        {audioData.Atitle}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grow>
              )}
          </Grid>
          {/* </Grid> */}
          {/* </Grid> */}
          {/* Audio Data ends  */}
        </Grid>
        <Grid
          item
          xs={12}
          style={{ height: "30%" }}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grow
            in={checked}
            style={{ transformOrigin: "0 0 0" }}
            {...(checked ? { timeout: 4000 } : {})}
          >
            <Grid
              xs={12}
              sx={{
                padding: "11%",
              }}
            >
              <button
                style={{
                  border: "none",
                  outline: "none",
                  width: "120px",
                  height: "50px",
                  borderRadius: "20px",
                  fontSize: "20px",
                  fontWeight: 600,
                  marginRight: "20px",
                  background:
                    "linear-gradient(45deg,rgba(81,200,255,1) 24%,rgba(0,35,95,1) 92%)",
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/app/Dashboard")}
              >
                Back
              </button>
              <Link to={"/app/moreResult"} target="_blank">
                <button
                  style={{
                    border: "none",
                    outline: "none",
                    width: "150px",
                    height: "50px",
                    borderRadius: "20px",
                    fontSize: "20px",
                    fontWeight: 600,
                    background:
                      "linear-gradient(45deg,rgba(81,200,255,1) 24%,rgba(0,35,95,1) 92%)",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                >
                  More Results
                </button>
              </Link>
            </Grid>
          </Grow>
        </Grid>
      </Grid>

      {open && <AudioPop open={open} setOpen={setOpen} url={audioData.Alink} />}
    </Grid>
  );
}

const styles = {
  leftbtm: {
    padding: "0px 40px",
  },
  btmtxt: {
    textAlign: "center",
    padding: "0px",
    margin: "0px",
    fontSize: "20px",
  },
  container: {
    width: "100%",
    height: "100%",
  },
  sub: {
    width: "100%",
    height: "100%",
  },
};

export default memo(AudioPage);
