import React, { memo } from "react";
import Modal from "@mui/joy/Modal";
import Sheet from "@mui/joy/Sheet";
import { ModalClose } from "@mui/joy";
import { Grid } from "@mui/material";
import AudioGif from "../assets/audioplay.gif";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

function AudioPop({ open, setOpen, url }) {
  return (
    <React.Fragment>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            minWidth: `calc(100% - 20%)`,
            minHeight: `calc(100% - 20%)`,
            maxHeight: `calc(100% - 20%)`,
            maxWidth: `calc(100% - 20%)`,
            borderRadius: "20px",
            p: 3,
            border: "2px solid #fff",
            boxShadow: "md",
          }}
          style={{ backgroundColor: "#1A3A63" }}
        >
          <ModalClose variant="plain" sx={{ m: 1, color: "#fff" }} />

          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              item
              xs={12}
              style={{
                alignItems: "center",
                width: "100%",
                textAlign: "center",
                height: "50vh",
              }}
            >
              <img
                src={AudioGif}
                alt="spike"
                style={{
                  width: "35%",
                  //   height: "70%",
                }}
              />
            </Grid>
            <Grid
              xs={12}
              style={{
                position: "relative",
                bottom: -50,
              }}
            >
              <AudioPlayer
                autoPlay
                src={url}
                onPlay={(e) => console.log("onPlay")}
                style={{
                  borderRadius: "20px",
                }}
                // other props here
              />
            </Grid>
          </Grid>
        </Sheet>
      </Modal>
    </React.Fragment>
  );
}

const styles = {
  txt: {
    fontSize: "16px",
    color: "#fff",
    fontWeight: 500,
    lineHeight: 1.5,
  },
  htxt: {
    fontSize: "24px",
    color: "#fff",
    fontWeight: 600,
  },
};

export default memo(AudioPop);
