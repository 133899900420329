import { Grid, Grow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import VideoBg1 from "../assets/videobg2.svg";
import AudioBg from "../assets/audiobg2.png";
import BlogBg from "../assets/blogbg1.png";
import RatriaLogo from "../assets/Ratria_Logo.png";
import VideoPop from "./VideoPop";
import BlogPop from "./BlogPop";
import AudioPop from "./AudioPop";
import { Link, useLocation, useNavigate } from "react-router-dom";

function NextPage() {
  const location = useLocation();

  const audioData = location.state.finalResult.audio[0];
  const videoData = location.state.finalResult.video[0];
  const contentData = location.state.finalResult.content[0];
  const [text, setText] = useState("");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [from, setFrom] = useState("Video");
  const divStyle = { width: `calc(100% - 15%)`, height: `calc(100% - 5%)` };
  const [checked, setChecked] = useState(true);

  // console.log("location.state.finalResult", location.state.finalResult);

  // console.log("audioData", audioData);
  // console.log("videoData", videoData);

  // console.log("contentData", contentData);

  const openVideo = (val) => {
    setOpen(true);
    setFrom(val);
  };
  const htmlToTaxt = () => {
    if (contentData != null) {
      const f = contentData?.Tcontent.replaceAll("rgb(255, 255, 255)", "")
        .replaceAll("rgb(0, 0, 0)", "white")
        .replaceAll("font-size: 16px", "font-size: 14px")
        .replaceAll("text-align: start", "text-align: justify");
      setText(f);
    }
  };

  useEffect(() => {
    if (contentData) {
      htmlToTaxt();
    }
  }, []);

  return (
    <Grid
      container
      style={divStyle}
      sx={{ display: "flex", flexDirection: "row" }}
    >
      <Grid item sm={7} xs={12} style={styles.container}>
        <Grid
          item
          container
          direction={"column"}
          xs={12}
          sx={{
            height: "70%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            paddingTop: "11%",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              paddingX: "8%",
            }}
          >
            {/* Video Data */}
            {videoData &&
            (videoData.Vlink || videoData.Vthumbnail || videoData.Vtitle) ? (
              <Grow
                in={checked}
                style={{ transformOrigin: "0 0 0" }}
                {...(checked ? { timeout: 2000 } : {})}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    height: "100%",
                    textAlign: "center",
                    backgroundImage: `url(${VideoBg1})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    width: "100%",
                    cursor: "pointer",
                    alignSelf: "center",
                    display: "flex",
                    flexDirection: "row",
                  }}
                  onClick={() => openVideo("Video")}
                >
                  <Grid item xs={1.5}></Grid>
                  <Grid item xs={12}>
                    {!videoData.Vthumbnail ? (
                      <div
                        style={{
                          width: "100%",
                          position: "relative",
                          top: "-10%",
                          height: "75%",
                          borderRadius: "20px",
                          background: "grey",
                          border: "2px #fff solid",
                        }}
                      ></div>
                    ) : (
                      <img
                        src={videoData.Vthumbnail}
                        alt="video Thumbnail"
                        style={{
                          width: "100%",
                          position: "relative",
                          top: "-10%",
                          height: "75%",
                          borderRadius: "20px",
                          border: "2px #fff solid",
                        }}
                      />
                    )}

                    <Grid
                      sx={{
                        position: "relative",
                        top: "-10%",
                        height: "25%",
                        overflow: "scroll",
                        marginBottom: "3%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { lg: "20px", md: "16px" },
                          fontWeight: 600,
                          textAlign: "center",
                        }}
                      >
                        {videoData.Vtitle}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={1.5}></Grid>
                </Grid>
              </Grow>
            ) : audioData &&
              (audioData.Alink || audioData.Athumbnail || audioData.Atitle) ? (
              <Grow
                in={checked}
                style={{ transformOrigin: "0 0 0" }}
                {...(checked ? { timeout: 2000 } : {})}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    height: "100%",
                    textAlign: "center",
                    backgroundImage: `url(${AudioBg})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    width: "100%",
                    cursor: "pointer",
                    alignSelf: "center",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                  }}
                  onClick={() => openVideo("Audio")}
                >
                  <Grid
                    sx={{
                      width: "90%",
                      marginBottom: "5%",
                      paddingX: "10%",
                      height: "25%",
                      overflow: "scroll",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xl: "24px", lg: "20px", md: "16px" },
                        fontWeight: 600,
                      }}
                    >
                      {audioData.Atitle}
                    </Typography>
                  </Grid>
                </Grid>
              </Grow>
            ) : (
              contentData &&
              (contentData.Tcontent || contentData.Ttittle) && (
                <Grow
                  in={checked}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(checked ? { timeout: 2000 } : {})}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      height: "100%",
                      textAlign: "center",
                      backgroundImage: `url(${BlogBg})`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      cursor: "pointer",
                      alignSelf: "center",
                      backgroundColor: "transparent",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      paddingBottom: "2%",
                    }}
                    onClick={() => openVideo("Blog")}
                  >
                    <img
                      style={{ paddingTop: "1%" }}
                      width="80px"
                      src={RatriaLogo}
                    />
                    <Typography
                      align="center"
                      sx={{
                        fontSize: { lg: "18px", md: "16px" },
                        fontWeight: 600,
                        padding: "2%",
                        paddingX: "10%",
                      }}
                    >
                      {contentData.Ttittle}
                    </Typography>
                    <Grid
                      sx={{
                        paddingX: "16%",
                        height: "90%",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        overflow: "scroll",
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: text,
                        }}
                      ></div>
                    </Grid>
                  </Grid>
                </Grow>
              )
            )}
            {/* Video Data Ends */}
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          style={{ height: "30%" }}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grow
            in={checked}
            style={{ transformOrigin: "0 0 0" }}
            {...(checked ? { timeout: 4000 } : {})}
          >
            <Grid
              xs={12}
              sx={{
                padding: "11%",
              }}
            >
              <button
                style={{
                  border: "none",
                  outline: "none",
                  width: "120px",
                  height: "50px",
                  borderRadius: "20px",
                  fontSize: "20px",
                  fontWeight: 600,
                  marginRight: "20px",
                  background:
                    "linear-gradient(45deg,rgba(81,200,255,1) 24%,rgba(0,35,95,1) 92%)",
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/app/Dashboard")}
              >
                Back
              </button>
              <Link to={"/app/moreResult"} target="_blank">
                <button
                  style={{
                    border: "none",
                    outline: "none",
                    width: "150px",
                    height: "50px",
                    borderRadius: "20px",
                    fontSize: "20px",
                    fontWeight: 600,
                    background:
                      "linear-gradient(45deg,rgba(81,200,255,1) 24%,rgba(0,35,95,1) 92%)",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                >
                  More Results
                </button>
              </Link>
            </Grid>
          </Grow>
        </Grid>
      </Grid>

      <Grid item sm={5} xs={12} style={styles.container}>
        {/* Audio Data  */}
        <Grid item xs={12} style={styles.sub}>
          <Grid
            item
            container
            direction={"column"}
            xs={12}
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              {videoData &&
              (videoData.Vlink || videoData.Vthumbnail || videoData.Vtittle) &&
              audioData &&
              (audioData.Acontent ||
                audioData.Alink ||
                audioData.Athumbnail ||
                audioData.Atitle) ? (
                <Grow
                  in={checked}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(checked ? { timeout: 2000 } : {})}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      height: "100%",
                      textAlign: "center",
                      backgroundImage: `url(${AudioBg})`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      width: "100%",
                      cursor: "pointer",
                      alignSelf: "center",
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "center",
                    }}
                    onClick={() => openVideo("Audio")}
                  >
                    <Grid
                      sx={{
                        width: "90%",
                        marginBottom: "5%",
                        paddingX: "10%",
                        height: "25%",
                        overflow: "scroll",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { lg: "20px", md: "16px" },
                          fontWeight: 600,
                        }}
                      >
                        {audioData.Atitle}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grow>
              ) : (
                contentData &&
                (contentData.Tcontent || contentData.Ttitle) && (
                  <Grow
                    in={checked}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(checked ? { timeout: 2000 } : {})}
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{
                        height: "100%",
                        textAlign: "center",
                        backgroundImage: `url(${BlogBg})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        cursor: "pointer",
                        alignSelf: "center",
                        backgroundColor: "transparent",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingBottom: "2%",
                      }}
                      onClick={() => openVideo("Blog")}
                    >
                      <img
                        style={{ paddingTop: "1%" }}
                        width="80px"
                        src={RatriaLogo}
                      />
                      <Typography
                        align="center"
                        sx={{
                          fontSize: { lg: "18px", md: "16px" },
                          fontWeight: 600,
                          padding: "2%",
                          paddingX: "10%",
                        }}
                      >
                        {contentData.Ttittle}
                      </Typography>
                      <Grid
                        sx={{
                          paddingX: "16%",
                          height: "90%",
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "center",
                          overflow: "scroll",
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: text,
                          }}
                        ></div>
                      </Grid>
                    </Grid>
                  </Grow>
                )
              )}
            </Grid>
          </Grid>
        </Grid>
        {/* Audio Data ends  */}

        {/* content Data  */}
        <Grid item xs={12} style={styles.sub1}>
          <Grid
            item
            container
            direction={"column"}
            xs={12}
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Grid
              item
              xs={10}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                paddingTop: "11%",
              }}
            >
              {videoData &&
                (videoData.Vlink ||
                  videoData.Vthumbnail ||
                  videoData.Vtittle) &&
                audioData &&
                (audioData.Acontent ||
                  audioData.Alink ||
                  audioData.Athumbnail ||
                  audioData.Atitle) &&
                contentData &&
                (contentData.Tcontent || contentData.Ttitle) && (
                  <Grow
                    in={checked}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(checked ? { timeout: 2000 } : {})}
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{
                        height: "100%",
                        textAlign: "center",
                        backgroundImage: `url(${BlogBg})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        cursor: "pointer",
                        alignSelf: "center",
                        backgroundColor: "transparent",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingBottom: "2%",
                      }}
                      onClick={() => openVideo("Blog")}
                    >
                      <img
                        style={{ paddingTop: "1%" }}
                        width="80px"
                        src={RatriaLogo}
                      />
                      <Typography
                        align="center"
                        sx={{
                          fontSize: { lg: "18px", md: "16px" },
                          fontWeight: 600,
                          padding: "2%",
                          paddingX: "10%",
                        }}
                      >
                        {contentData.Ttitle}
                      </Typography>
                      <Grid
                        sx={{
                          paddingX: "16%",
                          height: "90%",
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "center",
                          overflow: "scroll",
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: text,
                          }}
                        ></div>
                      </Grid>
                    </Grid>
                  </Grow>
                )}
            </Grid>
          </Grid>
        </Grid>
        {/* content Data Ends */}
      </Grid>

      {open && from === "Video" ? (
        <VideoPop open={open} setOpen={setOpen} url={videoData?.Vlink} />
      ) : from === "Audio" ? (
        <AudioPop open={open} setOpen={setOpen} url={audioData?.Alink} />
      ) : (
        <BlogPop
          open={open}
          setOpen={setOpen}
          content={contentData?.Tcontent}
          title={contentData?.Ttitle}
        />
      )}
    </Grid>
  );
}

const styles = {
  leftbtm: {
    padding: "0px 40px",
  },
  btmtxt: {
    textAlign: "center",
    padding: "0px",
    margin: "0px",
    fontSize: "20px",
  },
  container: {
    width: "100%",
    height: "100%",
  },
  sub: {
    width: "100%",
    height: "45%",
  },
  sub1: {
    width: "100%",
    height: "55%",
  },
};

export default NextPage;
