import { CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import AskMahatria from "../assets/AskMahatriaLogo.svg";
import MahatriaLogo from "../assets/MahatriaImage.svg";
import Watch from "../assets/Watch.svg";
import WatchActive from "../assets/WatchActive.svg";
import Read from "../assets/Read.svg";
import ReadActive from "../assets/ReadActive.svg";
import Listen from "../assets/Listen.svg";
import ListenActive from "../assets/ListenActive.svg";
import All from "../assets/All.svg";
import AllActive from "../assets/AllActive.svg";
import { useNavigate } from "react-router-dom";
import Search from "../assets/searchIcon.svg";
import { ToastContainer, toast } from "react-toastify";

function Dashboard() {
  const navigate = useNavigate();
  const [searchtxt, setSearchTxt] = useState("");
  const [action, setAction] = useState("Watch");
  const [loading, setLoading] = useState(false);
  const divStyle = { width: `calc(100% - 20%)` };

  console.log("action", action);
  // const getData = async () => {
  //   console.log("searchtxt", searchtxt);
  //   const response = await global.$axios.get(
  //     `infini/openai_search/?q=${searchtxt}`
  //   );
  //   console.log("audio", JSON.stringify(response.data.response.audio.length));
  //   console.log("video", JSON.stringify(response.data.response.video.length));
  //   console.log(
  //     "content",
  //     JSON.stringify(response.data.response.content.length)
  //   );

  //   console.log(
  //     "JSON.stringifyaudio",
  //     JSON.stringify(response.data.response.audio[0])
  //   );
  //   console.log(
  //     "JSON.stringifyvideo",
  //     JSON.stringify(response.data.response.video[0])
  //   );
  //   console.log(
  //     "JSON.stringifycontent",
  //     JSON.stringify(response.data.response.content[0])
  //   );
  //   if (response.data.status == 200) {
  //     localStorage.setItem("fullData", JSON.stringify(response.data.response));
  //     let audioId = response.data.response.audio[0].document.id;
  //     let videoId = response.data.response.video[0].document.id;
  //     let blogId = response.data.response.content[0].document.id;
  //     const getAudioResp = await global.$axios.get(
  //       `infini/finddocument/${audioId}`
  //     );
  //     const getVideoResp = await global.$axios.get(
  //       `infini/finddocument/${videoId}`
  //     );
  //     const getContentResp = await global.$axios.get(
  //       `infini/finddocument/${blogId}`
  //     );
  //     let finalResult = {
  //       audio: getAudioResp.data.response,
  //       video: getVideoResp.data.response,
  //       content: getContentResp.data.response,
  //     };
  //     console.log("finalResult", finalResult);
  // navigate("/app/Result", {
  //   state: { finalResult: finalResult, fullData: response.data.response },
  // });
  //   }
  // };

  const allResponse = async () => {
    try {
      setLoading(true);
      var payload = {
        query: searchtxt,
      };
      const response = await global.$axios.post(`file/all_search`, payload);
      // console.log("response.All", response.data.response);
      setLoading(false);
      if (
        response.data.response.video.length > 0 ||
        response.data.response.audio.length > 0 ||
        response.data.response.content.length > 0
      ) {
        localStorage.setItem(
          "fullData",
          JSON.stringify({
            audio: response.data.response.audio,
            content: response.data.response.content,
            video: response.data.response.video,
            type: "All",
          })
        );

        navigate("/app/Result", {
          state: {
            finalResult: response.data.response,
          },
        });
      } else {
        allResponse();
      }
      setLoading(false);
    } catch (error) {
      console.log("allResponse", error);
    }
  };

  const readResponse = async () => {
    try {
      setLoading(true);
      var payload = {
        query: searchtxt,
      };
      var response; // console.log("action", action);
      response = await global.$axios.post(`file/C_search`, payload);
      // console.log("response.data", response.data.response);

      if (response.data.response.length > 0) {
        localStorage.setItem(
          "fullData",
          JSON.stringify({ data: response.data.response, type: "content" })
        );
        navigate("/app/content", {
          state: {
            finalResult: response.data.response[0],
          },
        });
      } else {
        allResponse();
      }

      setLoading(false);
    } catch (error) {
      console.log("readResponse", error);
    }
  };

  const watchResponse = async () => {
    try {
      setLoading(true);
      var payload = {
        query: searchtxt,
      };
      var response = await global.$axios.post(`file/V_search`, payload);
      // console.log("response.Watch", response.data.response);
      if (response.data.response.length > 0) {
        localStorage.setItem(
          "fullData",
          JSON.stringify({ data: response.data.response, type: "video" })
        );
        navigate("/app/video", {
          state: {
            finalResult: response.data.response[0],
          },
        });
      } else {
        allResponse();
      }
      setLoading(false);
    } catch (error) {
      console.log("watchResponse", error);
    }
  };

  const listenResponse = async () => {
    try {
      setLoading(true);
      var payload = {
        query: searchtxt,
      };
      var response = await global.$axios.post(`file/a_search`, payload);
      // console.log("response.Watch", response.data.response);
      if (response.data.response.length > 0) {
        localStorage.setItem(
          "fullData",
          JSON.stringify({ data: response.data.response, type: "audio" })
        );

        navigate("/app/audio", {
          state: {
            finalResult: response.data.response[0],
          },
        });
      } else {
        allResponse();
      }
      setLoading(false);
    } catch (error) {
      console.log("listenResponse", error);
    }
  };

  const getData = async () => {
    // console.log("action1", action);
    setLoading(true);
    var payload = {
      query: searchtxt,
    };
    var response;
    if (action === "All") {
      allResponse();
    } else if (action === "Watch") {
      watchResponse();
    } else if (action === "Listen") {
      listenResponse();
      setLoading(false);
    } else if (action === "Read") {
      readResponse();
    } else {
      setLoading(false);
    }
    // console.log("responseRead", JSON.stringify(response.data));
  };

  useEffect(() => {
    document.onkeydown = async function (e) {
      // console.log("searchtxt", searchtxt);
      // console.log("enter", e.key, searchtxt && e.key == "Enter");

      if (searchtxt != "" && e.key == "Enter") {
        e.preventDefault();
        console.log("enter", action);
        await getData();
      }
    };
  }, [searchtxt, action]);

  return (
    <Grid container spacing={0} style={divStyle}>
      <motion.main
        className="main__container"
        initial={{ width: 0 }}
        animate={{ width: "100%" }}
        exit={{ x: "100%", opacity: 0 }}
        transition={{ duration: 2 }}
      >
        <Grid
          item
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} sm={6} style={{ width: "100%" }}>
            <img
              src={AskMahatria}
              alt="Mahatria"
              style={{
                width: "70%",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "95%",
                backgroundColor: "transparent",
                border: "5px solid #fff",
                borderRadius: "20px",
                height: "45px",
                padding: "0px 10px",
              }}
            >
              <input
                style={{
                  width: "90%",
                  backgroundColor: "transparent",
                  outline: "none",
                  border: "none",
                  borderRadius: "20px",
                  padding: "10px",
                  color: "#fff",
                  fontSize: "20px",
                }}
                value={searchtxt}
                onChange={(e) => setSearchTxt(e.target.value)}
                placeholder="How to live an abundant life"
              />
              {loading ? (
                <CircularProgress />
              ) : (
                <img
                  src={Search}
                  style={{
                    width: "30px",
                    cursor: "pointer",
                  }}
                  onClick={() => getData()}
                />
              )}
            </div>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px",
                marginTop: "10px",
              }}
            >
              {action == "Watch" ? (
                <img
                  src={WatchActive}
                  alt="Wtach"
                  style={{
                    width: "15%",
                    height: "15%",
                    cursor: "pointer",
                  }}
                  onClick={() => setAction("All")}
                />
              ) : (
                <img
                  src={Watch}
                  alt="Wtach"
                  style={{
                    width: "15%",
                    height: "15%",
                    cursor: "pointer",
                  }}
                  onClick={() => setAction("Watch")}
                />
              )}

              {action == "Listen" ? (
                <img
                  src={ListenActive}
                  alt="Wtach"
                  style={{
                    width: "15%",
                    height: "15%",
                    cursor: "pointer",
                  }}
                  onClick={() => setAction("All")}
                />
              ) : (
                <img
                  src={Listen}
                  alt="Wtach"
                  style={{
                    width: "15%",
                    height: "15%",
                    cursor: "pointer",
                  }}
                  onClick={() => setAction("Listen")}
                />
              )}

              {action == "Read" ? (
                <img
                  src={ReadActive}
                  alt="Wtach"
                  style={{
                    width: "15%",
                    height: "15%",
                    cursor: "pointer",
                  }}
                  onClick={() => setAction("All")}
                />
              ) : (
                <img
                  src={Read}
                  alt="Wtach"
                  style={{
                    width: "15%",
                    height: "15%",
                    cursor: "pointer",
                  }}
                  onClick={() => setAction("Read")}
                />
              )}

              {action == "All" ? (
                <img
                  src={AllActive}
                  alt="Wtach"
                  style={{
                    width: "13%",
                    height: "13%",
                    cursor: "pointer",
                  }}
                  onClick={() => setAction("All")}
                />
              ) : (
                <img
                  src={All}
                  alt="Wtach"
                  style={{
                    width: "13%",
                    height: "13%",
                    cursor: "pointer",
                  }}
                  onClick={() => setAction("All")}
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <img
              src={MahatriaLogo}
              alt="Mahatria"
              style={{
                width: "65%",
                height: "65%",
              }}
            />
          </Grid>
        </Grid>
      </motion.main>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Grid>
  );
}

const styles = {
  left: {
    flexDirection: "row",
  },
  input: {
    width: "70%",
    height: "6vh",
    outline: "none",
    border: "none",
    backgroundColor: "transparent",
    fontSize: "20px",
    color: "#fff",
    padding: "0px 10px",
    position: "relative",
    top: "-65px",
  },
};

export default Dashboard;
