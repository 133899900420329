import { Grid, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import JoditEditor from "jodit-react";
import { ToastContainer, toast } from "react-toastify";

function AddForm() {
  const [audioTitle, setAudioTitle] = useState("");
  const [audioThubmnail, setAudioThubmnail] = useState("");
  const [audioLink, setAudioLink] = useState("");
  const [audioContent, setAudioContent] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const [videoThubmNail, setVideoThumbNail] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [videoContent, setVideoContent] = useState("");
  const [blogTitle, setBlogTitle] = useState("");
  const [blogContet, setBlogConent] = useState("");
  const editor = useRef(null);

  const [loading, setLoading] = useState(false);
  const addformDetails = async () => {
    let payload = {
      Atitle: audioTitle,
      Athumbnail: audioThubmnail,
      Alink: audioLink,
      Acontent: audioContent,
      Vtitle: videoTitle,
      Vthumbnail: videoThubmNail,
      Vlink: videoLink,
      Vcontent: videoContent,
      Ttitle: blogTitle,
      Tcontent: blogContet,
      plot_embedding: "",
    };
    const response = await global.$axios.post("file/create ", payload);
    if (response.data.status == "success") {
      toast.success("File Added Successfully");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  const uploadForm = async (fileData, name) => {
    alert("ileData, name", fileData, name);
    toast.info("Please Wait Your File is Uploading");
    setLoading(true);
    let formData = new FormData();
    formData.append("file", fileData);
    // console.log("formData", formData);
    const response = await global.$axios.post("file/fileupload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("uploadForm", response.data.status);
    if (response.data.status == "success") {
      setLoading(false);
      if (name === "audioThumb") {
        setAudioThubmnail(response.data.message);
      } else if (name === "audioLink") {
        setAudioLink(response.data.message);
      } else if (name === "videoThumb") {
        setVideoThumbNail(response.data.message);
      }
    }
  };
  return (
    <Grid
      container
      spacing={0.5}
      style={{ paddingLeft: "20px", paddingBottom: "20px" }}
    >
      <Grid item xs={12}>
        <h3 style={styles.htxt1}>Upload Details</h3>
      </Grid>
      <Typography style={styles.htxt2}>Audio Details</Typography>
      <Grid item container xs={12} style={{ display: "flex" }}>
        <Grid item xs={4} style={styles.card}>
          <p style={styles.txt}>Audio Title</p>
          <TextField
            id="outlined-basic"
            variant="outlined"
            placeholder="Enter Audio Title"
            value={audioTitle}
            style={styles.input}
            size="small"
            onChange={(e) => setAudioTitle(e.target.value)}
          />
          <Typography></Typography>
        </Grid>
        <Grid item xs={4} style={styles.card}>
          <p style={styles.txt}>Audio ThumbNail</p>
          <TextField
            type="file"
            id="outlined-basic"
            variant="outlined"
            style={styles.input}
            size="small"
            onChange={(e) => {
              uploadForm(e.target.files[0], "audioThumb");
            }}
          />
        </Grid>
        <Grid item xs={4} style={styles.card}>
          <p style={styles.txt}>Audio Link</p>

          <TextField
            type="file"
            id="outlined-basic"
            variant="outlined"
            // value={audioLink}
            placeholder="Enter Audio Link"
            size="small"
            style={styles.input}
            onChange={(e) => {
              uploadForm(e.target.files[0], "audioLink");
            }}
          />
        </Grid>
        <Grid item xs={12} style={styles.card1}>
          <p style={styles.txt}>Audio Content</p>
          <JoditEditor
            ref={editor}
            value={audioContent}
            onBlur={(newContent) => setAudioContent(newContent)} // preferred to use only this option to update the content for performance reasons
            onChange={(e) => setAudioContent(e)}
          />
        </Grid>
      </Grid>

      <Typography style={styles.htxt2}>Video Details</Typography>
      <Grid item container xs={12} style={{ display: "flex" }}>
        <Grid item xs={4} style={styles.card}>
          <p style={styles.txt}>Video Title</p>
          <TextField
            id="outlined-basic"
            variant="outlined"
            placeholder="Enter VIdeo Title"
            value={videoTitle}
            style={styles.input}
            size="small"
            onChange={(e) => setVideoTitle(e.target.value)}
          />
        </Grid>
        <Grid item xs={4} style={styles.card}>
          <p style={styles.txt}>Video Thumb Nail</p>
          <TextField
            type="file"
            id="outlined-basic"
            variant="outlined"
            // value={videoThubmNail}
            style={styles.input}
            size="small"
            onChange={(e) => {
              uploadForm(e.target.files[0], "videoThumb");
            }}
          />
        </Grid>
        <Grid item xs={4} style={styles.card}>
          <p style={styles.txt}>Video Link</p>
          <TextField
            type="text"
            id="outlined-basic"
            placeholder="Enter Video Link"
            variant="outlined"
            style={styles.input}
            size="small"
            onChange={(e) => {
              setVideoLink(e.target.value);
              // uploadForm(e.target.files[0], "videoLink");
            }}
          />
        </Grid>
        <Grid item xs={12} style={styles.card1}>
          <p style={styles.txt}>Video Content</p>
          <JoditEditor
            ref={editor}
            value={videoContent}
            onBlur={(newContent) => setVideoContent(newContent)} // preferred to use only this option to update the content for performance reasons
            onChange={(e) => setVideoContent(e)}
          />
        </Grid>
      </Grid>
      <Typography style={styles.htxt2}>Blog Details</Typography>
      <Grid item container xs={12} style={{ display: "flex" }}>
        <Grid item xs={4} style={styles.card}>
          <p style={styles.txt}>Blog Title</p>
          <TextField
            id="outlined-basic"
            variant="outlined"
            value={blogTitle}
            style={styles.input}
            size="small"
            onChange={(e) => setBlogTitle(e.target.value)}
          />
        </Grid>
        <Grid item xs={4} style={styles.card}>
          <p style={styles.txt}>Blog Link</p>
          <TextField
            type="file"
            id="outlined-basic"
            variant="outlined"
            // value={blogLink}
            style={styles.input}
            size="small"
            onChange={(e) => {
              uploadForm(e.target.files[0], "blogLink");
            }}
          />
        </Grid>
        <Grid item xs={12} style={styles.card1}>
          <p style={styles.txt}>Blog Content</p>
          <JoditEditor
            ref={editor}
            value={blogContet}
            onBlur={(newContent) => setBlogConent(newContent)} // preferred to use only this option to update the content for performance reasons
            onChange={(e) => setBlogConent(e)}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ textAlign: "center" }}>
        {loading ? (
          <button style={styles.btn1} type="button" disabled={loading}>
            Save
          </button>
        ) : (
          <button
            style={styles.btn}
            type="button"
            onClick={() => addformDetails()}
          >
            Save
          </button>
        )}
      </Grid>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Grid>
  );
}

const styles = {
  htxt: {
    fontFamily: "Open Sans",
    fontSize: "20px",
    fontWeight: 600,
    color: "#232323",
    margin: "0px",
    padding: "7px 20px",
  },
  htxt1: {
    fontFamily: "Open Sans",
    fontSize: "18px",
    fontWeight: 600,
    color: "#232323",
    margin: "0px",
    padding: "0px",
  },
  htxt2: {
    fontFamily: "Open Sans",
    fontSize: "18px",
    fontWeight: 500,
    color: "#232323",
    margin: "0px",
    padding: "0px",
  },
  card: {
    margin: "0px 0px 10px 0px",
  },
  card1: {
    marginBottom: "10px",
    marginRight: "20px",
  },
  input: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 400,
    color: "#232323",
    width: "90%",
    alignItem: "center",
    justifyContent: "center",
  },
  txt: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 500,
    color: "#232323",
    margin: "0px",
    textAlign: "left",
  },
  btn: {
    border: "none",
    outline: "none",
    width: "120px",
    height: "50px",
    borderRadius: "20px",
    fontSize: "20px",
    fontWeight: 600,
    background:
      "linear-gradient(45deg,rgba(81,200,255,1) 24%,rgba(0,35,95,1) 92%)",
    color: "#fff",
    cursor: "pointer",
  },
  btn1: {
    border: "none",
    outline: "none",
    width: "120px",
    height: "50px",
    borderRadius: "20px",
    fontSize: "20px",
    fontWeight: 600,
    background: "#EFEFEF",
    color: "#fff",
    cursor: "pointer",
  },
};

export default AddForm;
