import { Button, Grid, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";

function ListAllData() {
  const newData = localStorage.getItem("fullData");
  const newData1 = JSON.parse(newData);
  const [audioData, setAudioData] = useState(
    newData1.data ? newData1.data : newData1.audio
  );
  const [videoData, setVideoData] = useState(
    newData1.data ? newData1.data : newData1.video
  );
  const [contentData, setCOntentData] = useState(
    newData1.data ? newData1.data : newData1.content
  );
  const divStyle = { width: `calc(100% - 10%)`, height: `calc(100% - 5%)` };
  const [activeBtn, setActiveBtn] = useState("video");
  console.log("hi");
  console.log("ausdioData", activeBtn);

  useEffect(() => {
    // setActiveBtn{newData1.type == 'audio' ? 'audio'}

    // if (newData1.type)
    switch (newData1.type) {
      case "audio":
        setActiveBtn("audio");
        break;
      case "video":
        setActiveBtn("video");
        break;

      case "content":
        setActiveBtn("content");
        break;
    }
  }, []);

  return (
    <Grid
      container
      spacing={0}
      style={divStyle}
      sx={{ display: "flex", flexDirection: "row" }}
    >
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {((activeBtn == "video" && newData1.type == "video") ||
          newData1.type == "All") && (
          <Button
            onClick={() => setActiveBtn("video")}
            variant={activeBtn == "video" ? "contained" : "outlined"}
            style={activeBtn == "video" ? styles.activebtn : styles.inActivebtn}
          >
            video
          </Button>
        )}
        {((activeBtn == "audio" && newData1.type == "audio") ||
          newData1.type == "All") && (
          <Button
            onClick={() => setActiveBtn("audio")}
            variant={activeBtn == "audio" ? "contained" : "outlined"}
            style={activeBtn == "audio" ? styles.activebtn : styles.inActivebtn}
          >
            Audio
          </Button>
        )}
        {(activeBtn == "content" || newData1.type == "All") && (
          <Button
            onClick={() => setActiveBtn("content")}
            variant={activeBtn == "content" ? "contained" : "outlined"}
            style={
              activeBtn == "content" ? styles.activebtn : styles.inActivebtn
            }
          >
            Content
          </Button>
        )}
      </Grid>

      {((activeBtn == "video" && newData1.type == "video") ||
        (activeBtn == "video" && newData1.type == "All")) && (
        <Grid
          item
          xs={12}
          container
          style={{ height: "90vh", overflow: "scroll" }}
        >
          {videoData?.map((item, index) => {
            var doc = new DOMParser().parseFromString(
              item?.Vcontent,
              "text/html"
            );
            let videoContent = doc.body.textContent || "";

            // let videoTitle = item?.Vtitle?.join(" ");
            // let videoContent = item?.Vcontent?.join(" ");
            return (
              <Fragment key={index}>
                {(item.Vtitle.length > 1 || item.Vcontent > 1) && (
                  <>
                    <Grid item xs={3} style={{ marginTop: "20px" }}>
                      <Typography style={styles.txt}>
                        Vector Distance :
                      </Typography>
                      <Typography style={styles.txt}>Video Title :</Typography>
                      <Typography style={styles.txt}>
                        Video ThumbNail :
                      </Typography>

                      <Typography style={styles.ctxt}>Video Chunk :</Typography>

                      <Typography style={styles.ctxt}>
                        Video Content :
                      </Typography>
                      <Typography style={styles.txt}>Video Link :</Typography>
                    </Grid>
                    <Grid item xs={9} style={{ marginTop: "20px" }}>
                      <Typography style={styles.txt1}>{item.score} </Typography>
                      <Typography style={styles.txt1}>
                        {item?.Vtitle ? item?.Vtitle : " - "}{" "}
                      </Typography>
                      <Typography style={styles.txt1}>
                        {item.Vthumbnail ? item.Vthumbnail : " - "}{" "}
                      </Typography>
                      <Typography style={styles.ctxt1}>
                        {item?.chunk ? item?.chunk : " - "}{" "}
                      </Typography>
                      <Typography style={styles.ctxt1}>
                        {item?.Vcontent ? videoContent : " - "}{" "}
                      </Typography>
                      <Typography style={styles.txt1}>
                        {item.Vlink ? item.Vlink : " - "}{" "}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Fragment>
            );
          })}
        </Grid>
      )}

      {((activeBtn == "audio" && newData1.type == "audio") ||
        (activeBtn == "audio" && newData1.type == "All")) && (
        <Grid
          item
          xs={12}
          container
          style={{ height: "90vh", overflow: "scroll" }}
        >
          {audioData.map((item, index) => {
            var doc = new DOMParser().parseFromString(
              item?.Acontent,
              "text/html"
            );
            let audioTitle = item.Atitle;
            let audioContent = doc.body.textContent || "";
            return (
              <Fragment key={index}>
                {(item.Atitle.length > 1 || item.Acontent > 1) && (
                  <>
                    <Grid item xs={3} style={{ marginTop: "20px" }}>
                      <Typography style={styles.txt}>
                        Vector Distance :
                      </Typography>
                      <Typography style={styles.txt}>Audio Title :</Typography>
                      <Typography style={styles.txt}>
                        Audio ThumbNail :
                      </Typography>
                      <Typography style={styles.ctxt}>Audio Chunk :</Typography>
                      <Typography style={styles.ctxt}>
                        Audio Content :
                      </Typography>
                      <Typography style={styles.txt}>Audio Link :</Typography>
                    </Grid>
                    <Grid item xs={9} style={{ marginTop: "20px" }}>
                      <Typography style={styles.txt1}>{item.score} </Typography>
                      <Typography style={styles.txt1}>
                        {audioTitle ? audioTitle : " - "}{" "}
                      </Typography>
                      <Typography style={styles.txt1}>
                        {item.Athumbnail ? item.Athumbnail : " - "}{" "}
                      </Typography>
                      <Typography style={styles.ctxt1}>
                        {item?.chunk ? item?.chunk : " - "}{" "}
                      </Typography>
                      <Typography style={styles.ctxt1}>
                        {audioContent ? audioContent : " - "}{" "}
                      </Typography>
                      <Typography style={styles.txt1}>
                        {item.Alink ? item.Alink : " - "}{" "}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Fragment>
            );
          })}
        </Grid>
      )}

      {((activeBtn == "content" && newData1.type == "content") ||
        (activeBtn == "content" && newData1.type == "All")) && (
        <Grid
          item
          xs={12}
          container
          style={{ height: "90vh", overflow: "scroll" }}
        >
          {contentData.map((item, index) => {
            var doc = new DOMParser().parseFromString(
              item?.Tcontent,
              "text/html"
            );
            let Title = item.Ttitle;
            let Content = doc.body.textContent || "";
            return (
              <Fragment key={index}>
                {(item.Ttitle.length > 1 || item.Tcontent > 1) && (
                  <>
                    <Grid item xs={3} style={{ marginTop: "20px" }}>
                      <Typography style={styles.txt}>
                        Vector Distance :
                      </Typography>
                      <Typography style={styles.txt}>Blog Title :</Typography>
                      <Typography style={styles.ctxt}>Blog Chunk :</Typography>
                      <Typography style={styles.ctxt}>
                        Blog Content :
                      </Typography>
                    </Grid>
                    <Grid item xs={9} style={{ marginTop: "20px" }}>
                      <Typography style={styles.txt1}>{item.score} </Typography>
                      <Typography style={styles.txt1}>
                        {Title ? Title : " - "}{" "}
                      </Typography>
                      <Typography style={styles.ctxt1}>
                        {item.chunk ? item.chunk : " - "}{" "}
                      </Typography>
                      <Typography style={styles.ctxt1}>
                        {Content ? Content : " - "}{" "}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Fragment>
            );
          })}
        </Grid>
      )}
    </Grid>
  );
}
const styles = {
  activebtn: {
    backgroundColor: "#005AAC",
    width: "120px",
    color: "#fff",
    marginRight: "10px",
    cursor: "pointer",
    height: "40px",
    border: "2px #005AAC solid",
  },
  inActivebtn: {
    backgroundColor: "transparent",
    width: "120px",
    color: "#fff",
    marginRight: "10px",
    cursor: "pointer",
    border: "2px #fff solid",
    height: "40px",
  },
  txt: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#fff",
    height: "40px",
    lineHeight: "40px",
  },
  txt1: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#fff",
    height: "40px",
    lineHeight: "40px",
    overflow: "scroll",
  },
  ctxt: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#fff",
    height: "90px",
    lineHeight: "40px",
  },
  ctxt1: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#fff",
    height: "90px",
    lineHeight: "40px",
    overflow: "scroll",
  },
};

export default ListAllData;
