import { Grid, Grow, Typography } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import BlogBg from "../assets/blogbg1.png";
import RatriaLogo from "../assets/Ratria_Logo.png";
import BlogPop from "./BlogPop";
import { Link, useLocation, useNavigate } from "react-router-dom";

function ContentPage() {
  const location = useLocation();
  const contentData = location.state.finalResult;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const divStyle = { width: `calc(100% - 15%)`, height: `calc(100% - 5%)` };
  const [checked, setChecked] = useState(true);

  // console.log("ContentPage", location.state);

  const openVideo = (val) => {
    setOpen(true);
  };
  var doc = new DOMParser().parseFromString(contentData.Tcontent, "text/html");
  let CONTENT = doc.body.textContent || "";

  return (
    <Grid
      container
      style={divStyle}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid item sm={7} xs={12} style={styles.container}>
        <Grid
          item
          container
          direction={"column"}
          xs={12}
          sx={{
            height: "70%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            paddingTop: "11%",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              paddingX: "8%",
            }}
          >
            {/* Video Data */}
            {contentData && (contentData.Tcontent || contentData.Ttitle) && (
              <Grow
                in={checked}
                style={{ transformOrigin: "0 0 0" }}
                {...(checked ? { timeout: 2000 } : {})}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    height: "100%",
                    textAlign: "center",
                    backgroundImage: `url(${BlogBg})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    cursor: "pointer",
                    alignSelf: "center",
                    backgroundColor: "transparent",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingBottom: "2%",
                  }}
                  onClick={() => openVideo()}
                >
                  <img
                    style={{ paddingTop: "1%" }}
                    width="80px"
                    src={RatriaLogo}
                  />
                  <Typography
                    align="center"
                    sx={{
                      fontSize: { lg: "18px", md: "16px" },
                      fontWeight: 600,
                      padding: "2%",
                      paddingX: "10%",
                    }}
                  >
                    {contentData.Ttitle}
                  </Typography>
                  <Grid
                    sx={{
                      paddingX: "16%",
                      height: "90%",
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      overflow: "scroll",
                    }}
                  >
                    <Typography
                      align="center"
                      sx={{
                        fontSize: { lg: "16px", md: "14px" },
                        fontWeight: 500,
                      }}
                    >
                      {CONTENT}
                    </Typography>
                  </Grid>
                </Grid>
              </Grow>
            )}
            {/* Video Data Ends */}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ height: "30%" }}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grow
            in={checked}
            style={{ transformOrigin: "0 0 0" }}
            {...(checked ? { timeout: 4000 } : {})}
          >
            <Grid
              xs={12}
              sx={{
                padding: "11%",
              }}
            >
              <button
                style={{
                  border: "none",
                  outline: "none",
                  width: "120px",
                  height: "50px",
                  borderRadius: "20px",
                  fontSize: "20px",
                  fontWeight: 600,
                  marginRight: "20px",
                  background:
                    "linear-gradient(45deg,rgba(81,200,255,1) 24%,rgba(0,35,95,1) 92%)",
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/app/Dashboard")}
              >
                Back
              </button>
              <Link to={"/app/moreResult"} target="_blank">
                <button
                  style={{
                    border: "none",
                    outline: "none",
                    width: "150px",
                    height: "50px",
                    borderRadius: "20px",
                    fontSize: "20px",
                    fontWeight: 600,
                    background:
                      "linear-gradient(45deg,rgba(81,200,255,1) 24%,rgba(0,35,95,1) 92%)",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                >
                  More Results
                </button>
              </Link>
            </Grid>
          </Grow>
        </Grid>
      </Grid>
      {open && (
        <BlogPop
          open={open}
          setOpen={setOpen}
          content={contentData?.Tcontent}
          title={contentData?.Ttitle}
        />
      )}
    </Grid>
  );
}

const styles = {
  leftbtm: {
    padding: "0px 40px",
  },
  btmtxt: {
    textAlign: "center",
    padding: "0px",
    margin: "0px",
    fontSize: "20px",
  },
  container: {
    width: "100%",
    height: "100%",
  },
  sub: {
    width: "100%",
    height: "45%",
  },
  sub1: {
    width: "100%",
    height: "55%",
  },
};

export default memo(ContentPage);
