import { Grid } from '@mui/material'
import React from 'react'
import Content from './Content'
import { Outlet } from 'react-router-dom'
import BgImg from '../assets/backgroundImg.png'

function MainLayout() {
    return (
        <Grid container spacing={0}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    color: "#fff",
                    height: "100vh",
                    width: '100%'
                }}
                style={{
                    backgroundImage: `url(${BgImg})`,
                    backgroundSize: "cover",
                    backgroundRepeat: 'round',
                }}
            >
                <Content>
                    <Outlet />
                </Content>

            </Grid>
        </Grid>
    )
}

export default MainLayout