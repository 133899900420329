import { Grid, Grow, Typography } from "@mui/material";
import React, { memo, useState } from "react";
import VideoBg1 from "../assets/videobg2.svg";
import VideoPop from "./VideoPop";
import { Link, useLocation, useNavigate } from "react-router-dom";

function VideoPage() {
  const location = useLocation();
  const videoData = location.state.finalResult;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const divStyle = { width: `calc(100% - 15%)`, height: `calc(100% - 5%)` };
  const [checked, setChecked] = useState(true);

  const openVideo = () => {
    setOpen(true);
  };

  return (
    <Grid
      container
      style={divStyle}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* <Grid item sm={2.5} xs={12} style={styles.container}></Grid> */}
      <Grid item sm={7} xs={12} style={styles.container}>
        <Grid
          item
          container
          direction={"column"}
          xs={12}
          sx={{
            height: "70%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            paddingTop: "11%",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              paddingX: "8%",
            }}
          >
            {/* Video Data */}
            {videoData &&
              (videoData.Vlink || videoData.Vthumbnail || videoData.Vtitle) && (
                <Grow
                  in={checked}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(checked ? { timeout: 2000 } : {})}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      height: "100%",
                      textAlign: "center",
                      backgroundImage: `url(${VideoBg1})`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      width: "100%",
                      cursor: "pointer",
                      alignSelf: "center",
                      display: "flex",
                      flexDirection: "row",
                    }}
                    onClick={() => openVideo("Video")}
                  >
                    <Grid item xs={1.5}></Grid>
                    <Grid item xs={12}>
                      {!videoData.Vthumbnail ? (
                        <div
                          style={{
                            width: "100%",
                            position: "relative",
                            top: "-10%",
                            height: "75%",
                            borderRadius: "20px",
                            background: "grey",
                            border: "2px #fff solid",
                          }}
                        ></div>
                      ) : (
                        <img
                          src={videoData.Vthumbnail}
                          alt="video Thumbnail"
                          style={{
                            width: "100%",
                            position: "relative",
                            top: "-10%",
                            height: "75%",
                            borderRadius: "20px",
                            border: "2px #fff solid",
                          }}
                        />
                      )}

                      <Grid
                        sx={{
                          position: "relative",
                          top: "-10%",
                          height: "25%",
                          overflow: "scroll",
                          marginBottom: "3%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { lg: "20px", md: "16px" },
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          {videoData.Vtitle}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={1.5}></Grid>
                  </Grid>
                </Grow>
              )}
            {/* Video Data Ends */}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ height: "30%" }}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grow
            in={checked}
            style={{ transformOrigin: "0 0 0" }}
            {...(checked ? { timeout: 4000 } : {})}
          >
            <Grid
              xs={12}
              sx={{
                padding: "11%",
              }}
            >
              <button
                style={{
                  border: "none",
                  outline: "none",
                  width: "120px",
                  height: "50px",
                  borderRadius: "20px",
                  fontSize: "20px",
                  fontWeight: 600,
                  marginRight: "20px",
                  background:
                    "linear-gradient(45deg,rgba(81,200,255,1) 24%,rgba(0,35,95,1) 92%)",
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/app/Dashboard")}
              >
                Back
              </button>
              <Link to={"/app/moreResult"} target="_blank">
                <button
                  style={{
                    border: "none",
                    outline: "none",
                    width: "150px",
                    height: "50px",
                    borderRadius: "20px",
                    fontSize: "20px",
                    fontWeight: 600,
                    background:
                      "linear-gradient(45deg,rgba(81,200,255,1) 24%,rgba(0,35,95,1) 92%)",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                >
                  More Results
                </button>
              </Link>
            </Grid>
          </Grow>
        </Grid>
      </Grid>

      {/* <Grid item sm={2.5} xs={12} style={styles.container}></Grid> */}

      {open && (
        <VideoPop open={open} setOpen={setOpen} url={videoData?.Vlink} />
      )}
    </Grid>
  );
}

const styles = {
  leftbtm: {
    padding: "0px 40px",
  },
  btmtxt: {
    textAlign: "center",
    padding: "0px",
    margin: "0px",
    fontSize: "20px",
  },
  container: {
    width: "100%",
    height: "100%",
  },
  sub: {
    width: "100%",
    height: "45%",
  },
  sub1: {
    width: "100%",
    height: "55%",
  },
};

export default memo(VideoPage);
